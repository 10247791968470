







































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  BLink,
  BFormRadioGroup,
  BFormGroup,
  BFormRadio,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BInputGroupPrepend,
  BButton,
  BFormCheckbox
} from "bootstrap-vue";
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, max } from "@validations";
import { CiviliteCreateModel } from "@/api/models/dossiers/civilite";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { TenantPreferenceEnum } from "@/api/models/enums/tenantPreferencesEnum";
import { EnumTenants } from "@/api/models/enums/enumTenants";
import appSettings from "@/appSettings";

extend('diffDaysPostDate', {
  params: [{name: "diffDaysPostDate"}],
  validate(value, params) {
    var maxDays = parseInt(Object.values(params)[0]);
    var currentDate = new Date();
    var inputDateSplitted = value.split('/')
    var inputDateFormatted = new Date(parseInt(inputDateSplitted![2]), parseInt(inputDateSplitted![1]) - 1, parseInt(inputDateSplitted![0]))
    var diff = inputDateFormatted.getTime() - currentDate.getTime();
    var daydiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return (daydiff <= maxDays);
  },
  message: (fieldName, {diffDaysPostDate}) => {
    return `Vous ne pouvez pas postdater le champ ${fieldName} de plus de ${diffDaysPostDate} jours.`;
  }
});

extend('diffDaysAntiDate', {
  params: [{name: "diffDaysAntiDate"}],
  validate(value, params) {    
    var minDays = parseInt(Object.values(params)[0]) ;
    var currentDate = new Date();
    var inputDateSplitted = value.split('/')
    var inputDateFormatted = new Date(parseInt(inputDateSplitted![2]), parseInt(inputDateSplitted![1]) - 1, parseInt(inputDateSplitted![0]))
    var diff = inputDateFormatted.getTime() - currentDate.getTime();
    var daydiff = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return daydiff >= -minDays
  },
  message: (fieldName, {diffDaysAntiDate}) => {
    return `Vous ne pouvez pas antidater le champ ${fieldName} de plus de ${diffDaysAntiDate} jours.`;
  }
});

@Component({
  components: {
    BFormRadioGroup,
    BFormGroup,
    BFormRadio,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    RessifnetDateInput,
    BButton,
    BFormCheckbox,
    Cleave
  },
  name: "dossier-civilite",
})
export default class DossierCivilite extends Vue {
  tenantId = appSettings.getTenantId();
  tenants = EnumTenants;
  
  
  model : CiviliteCreateModel = {
    dateCreation: (new Date().toLocaleString('fr-FR', { day: 'numeric', month: 'numeric', year: 'numeric' })),
    sexe: undefined
  };

  timeout: any;

  ssnMask = {
    blocks: [1, 2, 2, 2, 3, 3, 2]
  }

  required = required;
  min = min;
  max = max;

  options: any = [
    { text: "Masculin", value: 1 },
    { text: "Féminin", value: 0 },
    { text: "Non connu/Autres", value: null },
  ];

  getFormattedData(){
    return this.model;
  }

  get tenantPreferences() {
    return this.$store.state.user.tenant_preferences
  }

  get preference() {
    return this.tenantId == EnumTenants.ACTIS;
  }
  
  dossierExistantResult: PaginatedList<any> | null = null;

  @Watch("model.sexe")
  async sexeChange(){
    console.log(this.model.sexe)
  }

  @Watch("model.nom")
  async nomChange() {
    await this.checkDossierExistant();
  }

  async checkDossierExistant() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (this.model.nom && this.model.prenom) {
        await this.$http.ressifnet.dossiers
          .search({
            nom: this.model.nom,
            prenom: this.model.prenom,
          })
          .then((response: PaginatedList<any>) => {
            this.$emit('dossiers-existants', response);
            this.dossierExistantResult = response;
          });
      }
    }, 3000);
  }

  async defaultDate() {
    this.model = { 
      ...this.model,
      dateNaissance: new Date("01/01/1900").toLocaleString()
    }
    this.$swal({
      title: 'Date de naissance par défaut',
      text: 'Veuillez prendre note qu\'en saisissant la date de naissance avec une valeur par défaut, celle-ci ne sera pas utilisable dans les statistiques',
      icon: 'warning',
      customClass: {
        confirmButton: 'btn btn-warning',
      },
      buttonsStyling: false,
      confirmButtonText: 'J\'en prends note',
    })
  }
}
