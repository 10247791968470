









































import { Component, Vue, Watch } from "vue-property-decorator";
import DossierCivilite from "./_Civilite.vue";
import DossierCoordonnees from "./_Coordonnees.vue";
import DossierInformationsContrat from "./_InformationsContrat.vue";
import { CiviliteCreateModel } from "@/api/models/dossiers/civilite";
import { DossierCreateModel } from "@/api/models/dossiers/dossier";
import { CoordonneesCreateModel } from "@/api/models/dossiers/coordonnees";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationObserver } from "vee-validate";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import { BButton, BRow, BCol, BFormGroup, BFormInput } from "bootstrap-vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { POSITION } from "vue-toastification";

@Component({
  components: {
    ValidationObserver,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    DossierCivilite,
    DossierCoordonnees,
    DossierInformationsContrat,
    FormWizard,
    TabContent,
    ToastificationContent
  },
})
export default class CreateAccompagnementIndividuel extends Vue {
  droitsEnum = DroitsEnum;
  dossierExistantResult: PaginatedList<any> | null = null;
  searchLoading = false;

  model: DossierCreateModel = {};

  showStepper = false;

  civiliteValidation: any = false;
  coordonneesValidation: any = false;
  infoContratValidation: any = false;

  async mounted() {
    await this.activateAlltab();
  }

  created() {
    this.listenUserInteraction();
  }

  toggleToast(title:string){
      this.$toast({
      component: ToastificationContent,
      props: {
          title: `Problème(s) sur l'onglet : ${title}`,
          icon: 'XIcon',
          text: 'Une ou plusieurs erreurs sont présentes dans le formulaire',
          variant: 'danger',
      },
      },
      {
      timeout: 8000,
      position: POSITION.TOP_RIGHT,
      })
  }

  async beforeRouteLeave(to: any, from: any, next: any) {

    this.civiliteValidation = await this.validationFormCivilites();
    this.coordonneesValidation = await this.validationFormCoordonnes();
    this.infoContratValidation = await this.validationFormInfosContrat();

    var isValid = this.civiliteValidation && this.coordonneesValidation && this.infoContratValidation;

    this.model.civilite = (this.$refs["civiliteRules"] as any).getFormattedData();

    if (
      (this.model.civilite?.nom ||
      this.model.civilite?.prenom ) && !isValid
    ) {
      let answer = window.confirm(
        "Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir quitter la page ?"
      );
      if (answer) {
        try {
          next();
        } catch (err) {
          next(false);
        }
      } else {
        next(false);
      }
    } else {
      next();
    }
  }

  listenUserInteraction() {
    window.addEventListener("beforeunload", function (event) {
      event.returnValue =
        "Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir quitter la page ?";
    });
  }

  async activateAlltab() {
    (this.$refs["formSaveDossier"] as any).changeTab(2, 1);
    (this.$refs["formSaveDossier"] as any).changeTab(1, 0);

    this.showStepper = true;
  }

  countErrorCivilite() {
    var civilite = (this.$refs["civiliteRules"] as any)!.$refs[
      "civiliteFormToValidate"
    ];
    var countError = 0;
    Object.entries(civilite.fields).forEach((x: any) => {
      if (x[1].invalid == true) {
        countError++;
      }
    });
    return countError;
  }

  countErrorCoordonnees() {
    var coordonnees = (this.$refs["coordonnesRules"] as any)!.$refs[
      "coordonnesFormToValidate"
    ];
    var countError = 0;
    Object.entries(coordonnees.fields).forEach((x: any) => {
      if (x[1].invalid == true) {
        countError++;
      }
    });
    return countError;
  }

  countErrorInfoContrat() {
    var infoContrat = (this.$refs["infosContratRules"] as any)!.$refs[
      "infoContratFormToValidate"
    ];
    var countError = 0;
    Object.entries(infoContrat.fields).forEach((x: any) => {
      if (x[1].invalid == true) {
        countError++;
      }
    });
    return countError;
  }

  async watchCiviliteCorrection(interval?:any){
    this.civiliteValidation = await this.validationFormCivilites();
    var element = document.getElementById("step-Civilité0");
    element?.classList.add("showError");
    if(!this.civiliteValidation){
      element!.dataset.title = this.countErrorCivilite().toString();
    }else {
      element?.classList.remove("showError");
      clearInterval(interval);
    }
  }

  async watchCoordonnesCorrection(interval?:any){
    this.coordonneesValidation = await this.validationFormCoordonnes();
    var element = document.getElementById("step-Coordonnées1");
    element?.classList.add("showError");
    if(!this.coordonneesValidation){
      element!.dataset.title = this.countErrorCivilite().toString();
    }else {
      element?.classList.remove("showError");
      clearInterval(interval);
    }
  }

  async watchInfosContratCorrection(interval?:any){
    this.infoContratValidation = await this.validationFormInfosContrat();
    var element = document.getElementById("step-Informationsdecontrat2");
    element?.classList.add("showError");
    if(!this.infoContratValidation){
      element!.dataset.title = this.countErrorInfoContrat().toString();
    }else {
      element?.classList.remove("showError");
      clearInterval(interval);
    }
  }

  async saveDossier() {
    this.civiliteValidation = await this.validationFormCivilites();
    this.coordonneesValidation = await this.validationFormCoordonnes();
    this.infoContratValidation = await this.validationFormInfosContrat();

    if (
      !this.civiliteValidation ||
      !this.coordonneesValidation ||
      !this.infoContratValidation
    ) {
      if (!this.civiliteValidation) {
        this.watchCiviliteCorrection();
        var interval1 = setInterval(() => this.watchCiviliteCorrection(interval1),1000)
        this.toggleToast('Civilité');
      } 

      if (!this.coordonneesValidation) {
        this.watchCoordonnesCorrection();
        var interval2 = setInterval(() => this.watchCoordonnesCorrection(interval2),1000)
        this.toggleToast('Coordonnées');
      }

      if (!this.infoContratValidation) {
        this.watchInfosContratCorrection();
        var interval3 = setInterval(() => this.watchInfosContratCorrection(interval3),1000)
        this.toggleToast('Information de contrat');
      } 

      return;
    } else {
      var civiliteStep = document.getElementById("step-Civilité0");
      civiliteStep?.classList.remove("showError");
      var coordoneesStep = document.getElementById("step-Coordonnées1");
      coordoneesStep?.classList.remove("showError");
      var infoContratStep = document.getElementById(
        "step-Informationsdecontrat2"
      );
      infoContratStep?.classList.remove("showError");

      this.model.civilite = (
        this.$refs["civiliteRules"] as any
      ).getFormattedData();
      this.model.coordonnees = (
        this.$refs["coordonnesRules"] as any
      ).getFormattedData();
      this.model.informationsContrat = (
        this.$refs["infosContratRules"] as any
      ).getFormattedData();
      await this.$http.ressifnet.dossiers.post(this.model).then(
        async (response: any) => {
          this.model = {};
          this.$router
            .push({
              name: "details-accompagnement-individuel",
              params: { id: response },
            })
            .catch(() => {});
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
    }
  }

  validationFormCivilites() {
    return new Promise((resolve) => {
      if (this.$refs["civiliteRules"] as any) {
        (this.$refs["civiliteRules"] as any)!.$refs["civiliteFormToValidate"]
          .validate()
          .then((success: any) => {
            resolve(success);
          });
      }
    });
  }

  validationFormCoordonnes() {
    return new Promise((resolve) => {
      if (this.$refs["coordonnesRules"] as any) {
        (this.$refs["coordonnesRules"] as any)!.$refs[
          "coordonnesFormToValidate"
        ]
          .validate()
          .then((success: any) => {
            resolve(success);
          });
      }
    });
  }

  validationFormInfosContrat() {
    return new Promise((resolve) => {
      if (this.$refs["infosContratRules"] as any) {
        (this.$refs["infosContratRules"] as any)!.$refs[
          "infoContratFormToValidate"
        ]
          .validate()
          .then((success: any) => {
            resolve(success);
          });
      }
    });
  }

  setDossiersExistants(existants: any) {
    this.dossierExistantResult = existants;
  }

  goToRef(refname: string) {
    setTimeout(() => {
      let formCard = this.$refs[refname] as any;
      if (formCard) formCard.scrollIntoView({ behavior: "smooth" });
    }, 50);
  }

  createSansDossier() {
    this.$router.push({ name: "create-nouveau-sans-dossier" });
  }

  anonymizeDossier() {
    (this.$refs["CiviliteForm"] as any).model = {
      ...(this.$refs["CiviliteForm"] as any).model,
      sexe: null,
      permis: false,
      prenom: "ANONYME",
      nom: "ANONYME",
      dateNaissance: new Date("01/01/1900").toLocaleString(),
    };
    (this.$refs["CoordonneesForm"] as any).model = {
      telephone1: "0000000000",
    };
  }
}
