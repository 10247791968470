

























































































































































































import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, max } from '@validations'
import {
  BFormInput, BButton, BFormTextarea
} from 'bootstrap-vue'
import { CoordonneesCreateModel } from "@/api/models/dossiers/coordonnees";
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.fr'


@Component({
	components: {
    ValidationProvider,
    BFormInput,
    ValidationObserver,
    Cleave,
    BButton,
    BFormTextarea
  },
  name: "dossier-coordonnees"
})
export default class DossierCoordonnees extends Vue {

 model: CoordonneesCreateModel = {};

 getFormattedData(){
    return this.model;
  }

  maskOptions = {
    phone: true,
    phoneRegionCode: 'FR',
  };

  required = required;
  min = min;
  max = max;
}
