




































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormTextarea,
  VBTooltip,
  BButton
} from "bootstrap-vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { TypeContrat } from "@/api/models/options/typeContrats/typeContrat";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { CategorieSocioPro } from "@/api/models/options/categoriesSocioPro/categorieSocioPro";
import { Horaire } from "@/api/models/options/horaires/horaire";
import { TempTravail } from "@/api/models/options/tempTravails/tempTravail";
import { InformationsContratCreateModelForm } from "@/api/models/dossiers/situationProfessionnelle";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue"
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Site } from "@/api/models/options/entreprises/site";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { Tag } from "@/api/models/options/entreprises/tag";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    SearchableVueSelect,
    RessifnetDateInput,
    BButton
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  name: "dossier-informations-contrat",
})
export default class DossierInformationsContrat extends Vue {
  listTypeContrat: PaginatedList<TypeContrat> = new PaginatedList<TypeContrat>();
  listCategorieSocioPro: PaginatedList<CategorieSocioPro> = new PaginatedList<CategorieSocioPro>();
  listHoraire: PaginatedList<Horaire> = new PaginatedList<Horaire>();
  listTempTravail: PaginatedList<TempTravail> = new PaginatedList<TempTravail>();

  refreshIndex = 1;

  model: InformationsContratCreateModelForm = {

      brancheLibelle: "Aucune branche selectionnée",
      regionLibelle: "Aucune région selectionnée",

      totalCountEntreprise: 0,
      listeEntreprises: [],

      totalCountSite: 0,
      listeSites: [],

      totalCountTag: 0,
      listeTags: [],

      totalCountSecteur: 0,
      listeSecteurs: [],

      horaireId: null,
      typeContratId: null,
      categorieSocioProId: null,
      tempsTravailId: null
    }; 

  async created() {
      await this.loadListTypeContrats(),
      await this.loadlistCategorieSocioPros(),
      await this.loadListHoraires(),
      await this.loadListTempsTravails();
  }

  getFormattedData(){
    return this.model;
  }

  async searchEntreprises(params: any, item: InformationsContratCreateModelForm) {
    if (item.entrepriseId == null || item.entrepriseId == undefined){
      if (params?.reset)
        item.listeEntreprises = item.listeEntreprises?.filter(e => e.id == item.entrepriseId);

      await this.$http.ressifnet.entreprises.paginatedList(params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search ?? '')
        .then((res: PaginatedList<Entreprise>) => {
          item.totalCountEntreprise = res.totalCount
          item.listeEntreprises = item.listeEntreprises!.concat(res.items.filter(ri => !item.listeEntreprises!.some(lsi => lsi.id == ri.id)))
          if(item.listeEntreprises.length === 1){
            this.model.entrepriseId = item.listeEntreprises[0].id
            this.onEntrepriseSelected(null)
          }
          
          if(this.model.entrepriseId != undefined) this.searchSites(null, item);
        })
    }
  }

  async searchSites(params: any, item: InformationsContratCreateModelForm) {
    if (item.siteId == null || item.siteId == undefined){
      if (!params || params?.reset) {
        item.listeSites = item.listeSites?.filter(e => e.id == item.siteId);
        item.totalCountSite = item.listeSites?.length
      }

      await this.$http.ressifnet.sites
        .paginatedList(item.entrepriseId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
        .then((res: PaginatedList<Site>) => {
          item.totalCountSite = res.totalCount
          item.listeSites = item.listeSites!.concat(res.items.filter(ri => !item.listeSites!.some(lsi => lsi.id == ri.id))
            .map((x: Site) => <Site>{
              ...x,
              completeLabel: `${x.libelle} (${x.regionLibelle})`
            })
          )
          if(item.listeSites.length === 1){
            this.model.siteId = item.listeSites[0].id
            this.onSiteSelected(this.model.siteId, item)
          }
          
          if (this.model.siteId != undefined) this.searchSecteurs(null, item);
        })
    }
  }

  async searchTags(params: any, item: InformationsContratCreateModelForm) {
    if (!params || params?.reset)
      item.listeTags = item.listeTags?.filter(e => item.tagsId?.some(id => id == e.id));
    item.totalCountTag = item.listeTags?.length

    await this.$http.ressifnet.tags
      .paginatedList(item.entrepriseId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Tag>) => {
        item.totalCountTag = res.totalCount
        item.listeTags = item.listeTags!.concat(res.items.filter(ri => !item.listeTags!.some(lsi => lsi.id == ri.id)))
      })
  }

  async searchSecteurs(params: any, item: InformationsContratCreateModelForm) {
    if (item.secteurId == null || item.secteurId == undefined){
      if (!params || params?.reset) {
        item.listeSecteurs = item.listeSecteurs?.filter(e => e.id == item.secteurId);
        item.totalCountSecteur = item.listeSecteurs?.length
      }

      await this.$http.ressifnet.secteurs
        .paginatedListForSite(item.entrepriseId, item.siteId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
        .then((res: PaginatedList<Secteur>) => {
          item.totalCountSecteur = res.totalCount
          item.listeSecteurs = item.listeSecteurs!.concat(res.items.filter(ri => !item.listeSecteurs!.some(lsi => lsi.id == ri.id)))
          item.secteurId = item.listeSecteurs.length === 1 ? item.listeSecteurs[0].id : undefined;
        })
    }
  }

  onSiteSelected(val: any, item: InformationsContratCreateModelForm) {
    if (val) {
      const site = item.listeSites?.filter(s => s.id == val)[0]
      item.brancheLibelle = site?.brancheLibelle ?? "Ce site n'a pas de branche"
      item.regionLibelle = site?.regionLibelle ?? "Ce site n'a pas de région"
    }
    else {
      item.brancheLibelle = "Aucune branche selectionné"
      item.regionLibelle = "Aucune région selectionné"
    }
    item.secteurId = undefined
  }

  onClearEntreprise(item: InformationsContratCreateModelForm) {
    item.entrepriseId = undefined
    item.siteId = undefined
    item.secteurId = undefined
    item.tagsId = []

    item.listeSites = []
    item.listeSecteurs = []
    item.listeTags = []

    this.searchEntreprises(null, item)
  }

  onClearSite(item: InformationsContratCreateModelForm) {
    item.siteId = undefined
    item.secteurId = undefined
    item.listeSecteurs = []

    this.searchSites(null, item)
  }


  async loadListTempsTravails() {
    await this.$http.ressifnet.tempsTravail.paginatedList(1, 50, true).then(
      (response: PaginatedList<TempTravail>) => {
        this.listTempTravail = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des temps de travail",
        });
      }
    );
  }

  async loadListHoraires() {
    await this.$http.ressifnet.horaires.paginatedList(1, 50, true).then(
      (response: PaginatedList<Horaire>) => {
        this.listHoraire = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des horaires",
        });
      }
    );
  }

  async loadListTypeContrats() {
    await this.$http.ressifnet.typeContrats.paginatedList(1, 50, true).then(
      (response: PaginatedList<TypeContrat>) => {
        this.listTypeContrat = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des types de contrats",
        });
      }
    );
  }

  async loadlistCategorieSocioPros() {
    await this.$http.ressifnet.categoriesSocioPro
      .paginatedList(1, 50, true)
      .then(
        (response: PaginatedList<CategorieSocioPro>) => {
          this.listCategorieSocioPro = response;
        },
        (error: any) => {
          errorAlert.fire({
            title:
              "Erreur lors du chargement des categories socio-professionnelles",
          });
        }
      );
  }

  resetForm() {
    this.model =
    {
      brancheLibelle: "Aucune branche selectionné",
      regionLibelle: "Aucune région selectionné",

      totalCountEntreprise: 0,
      listeEntreprises: [],

      totalCountSite: 0,
      listeSites: [],

      totalCountSecteur: 0,
      listeSecteurs: [],

      totalCountTag: 0,
      listeTags: [],

      horaireId: null,
      typeContratId: null,
      categorieSocioProId: null,
      tempsTravailId: null,
      dateDebut: undefined
    };
  }

  defaultDate(item: InformationsContratCreateModelForm) {
    Vue.set(item, "dateDebut", new Date("01/01/1900").toLocaleString())
  }

  async onEntrepriseSelected(indexItem: any) {
    console.log(this.model.entrepriseId);
    
    this.model.listeSites = []
    this.model.listeSecteurs = []
    this.model.listeTags = []
    this.model.siteId = undefined
    this.model.secteurId = undefined
    this.model.tagsId = []
    this.model.brancheLibelle = "Aucune branche selectionné"
    this.model.regionLibelle = "Aucune région selectionné"

    this.onClearSite(this.model)

    await this.searchSites(null, this.model);
    await this.searchTags(null, this.model)
    await this.searchSecteurs(null, this.model)
  }
}
